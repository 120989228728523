import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import CustomAdIcon from '~/assets/svg/icon-ad.svg?react';
import ExternalLinkIcon from '~/assets/svg/icon-external-link.svg?react';
import CustomStatsIcon from '~/assets/svg/icon-graph.svg?react';
import CustomHomeIcon from '~/assets/svg/icon-home.svg?react';
import CustomMessageIcon from '~/assets/svg/icon-message.svg?react';
import CustomOrderIcon from '~/assets/svg/icon-order.svg?react';
import { defaultParams as conversationDefaultParams } from '~/helpers/conversation';
import { MerchantExternalRouting, RoutingUrl, WIZVILLE_EXTENAL_LINK } from '~/helpers/general';

import './NavMenu.scss';

function CustomMenuIcon({ icon }) {
  return (
    <>
      <Icon component={icon} />
    </>
  );
}

function LinkHiddenIcon() {
  return (
    <>
      <Icon component={ExternalLinkIcon} className='external-link-hidden-icon' style={{ fontSize: '21px' }} />
    </>
  );
}

function NavMenu({ location, activeMerchantSlug, onClick = () => {} }) {
  const mainMenuItems = [
    {
      key: RoutingUrl.home(),
      title: 'Accueil',
      icon: <CustomMenuIcon icon={CustomHomeIcon} />,
      label: (
        <Link to={RoutingUrl.home()}>
          <span className='h4'>Accueil</span>
        </Link>
      ),
    },
    {
      key: '2',
      title: 'Commandes',
      icon: <CustomMenuIcon icon={CustomOrderIcon} />,
      label: (
        <a target='_blank' rel='noopener noreferrer' href={MerchantExternalRouting.orders(activeMerchantSlug)}>
          <span className='h4'>Commandes</span>
        </a>
      ),
    },
    {
      key: RoutingUrl.catalogue(),
      title: 'Annonces',
      icon: <CustomMenuIcon icon={CustomAdIcon} />,
      label: (
        <Link to={RoutingUrl.catalogue()}>
          <span className='h4'>Annonces</span>
        </Link>
      ),
    },
    {
      key: RoutingUrl.conversations(),
      title: 'Messagerie',
      icon: <CustomMenuIcon icon={CustomMessageIcon} />,
      label: (
        <Link to={RoutingUrl.conversations([], conversationDefaultParams)}>
          <span className='h4'>Messagerie</span>
        </Link>
      ),
    },
    {
      key: 'sub1',
      icon: <CustomMenuIcon icon={CustomStatsIcon} />,
      label: <span className='h4'>Activités</span>,
      children: [
        {
          key: '4',
          label: (
            <a target='_blank' rel='noopener noreferrer' href={MerchantExternalRouting.analytics(activeMerchantSlug)}>
              <span className='submenu-external-link-group body1'>
                - Tableau de bord
                <LinkHiddenIcon />
              </span>
            </a>
          ),
        },
        {
          key: '5',
          label: (
            <a target='_blank' rel='noopener noreferrer' href={WIZVILLE_EXTENAL_LINK}>
              <span className='submenu-external-link-group body1'>
                - Répondre aux avis clients
                <LinkHiddenIcon />
              </span>
            </a>
          ),
        },
        {
          key: '6',
          label: (
            <a target='_blank' rel='noopener noreferrer' href={MerchantExternalRouting.finance(activeMerchantSlug)}>
              <span className='submenu-external-link-group body1'>
                - Finances
                <LinkHiddenIcon />
              </span>
            </a>
          ),
        },
      ],
    },
  ];

  const [openKeys, setOpenKeys] = useState([]);

  const openChange = (newOpenKeys) => {
    // Only keep open the new OpenKeys (so only one submenu active at one time)
    setOpenKeys(newOpenKeys.filter((x) => !openKeys.includes(x)));
  };

  return (
    <>
      <Menu
        items={mainMenuItems}
        mode='inline'
        className='ant-menu-custom'
        style={{ border: 0 }}
        onClick={() => {
          setOpenKeys([]);
          onClick();
        }}
        selectedKeys={[location.pathname]}
        openKeys={openKeys}
        onOpenChange={openChange}
      />
    </>
  );
}

export default withRouter(
  connect((state) => ({
    activeMerchantSlug: state.session.active_merchant.merchant_slug,
  }))(NavMenu)
);
