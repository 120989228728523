import { useQueryClient } from '@tanstack/react-query';
import { Flex, Spin, Switch } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import PhotoroomExplanationImg from '~/assets/photoroom-explanation.jpg';
import Dialogue from '~/components/shared/Dialogue/Dialogue';
import ExpandableContent from '~/components/shared/ExpandableContent/ExpandableContent';
import { PROMOTION_SETTINGS_KEY, useMerchantSettings, useTogglePhotoroomSettings } from '~/hooks/useMerchantSettings';

function AdsSettings({ activeMerchantId }) {
  const queryClient = useQueryClient();
  const togglePhotoroomSettings = useTogglePhotoroomSettings(activeMerchantId, queryClient);
  const { isLoading, data } = useMerchantSettings(activeMerchantId);
  if (isLoading) return <Spin />;
  const { [PROMOTION_SETTINGS_KEY]: photoroomDisabled } = data;

  return (
    <ExpandableContent title='Mes annonces' titleClassName='body1' withArrow>
      <Flex vertical gap={16} className='py-l'>
        <Dialogue type='warning' light message='Veuillez contacter votre accompagnateur·rice Label Emmaüs si vous souhaitez modifier ce paramètre. ' />
        <img src={PhotoroomExplanationImg} alt='Photoroom' width={130} height={130} />
        <Flex gap={8}>
          <Switch checked={!photoroomDisabled} onChange={() => togglePhotoroomSettings.mutate()} loading={togglePhotoroomSettings.isPending} />
          <p className='body1'>Détourer automatiquement la première photo de mon annonce avec Photoroom.</p>
        </Flex>
      </Flex>
    </ExpandableContent>
  );
}

export default connect(({ session }) => ({ activeMerchantId: session.active_merchant.id }))(AdsSettings);
